<template>
  <div class="page-out">
    <div :class="isPcMobileClass()">
      <div class="page-box">
        <div class="img-box">
          <img
            :src="status === 2 ? successImg : failImg"
            alt=""
          >
        </div>
        <div class="page-title">
          {{ status === 2 ? successText : failText }}
        </div>
        <div class="page-content">
          <view-match :list="basicList" />
        </div>
      </div>
      <div
        v-if="status === 1"
        class="bottom-div marginLR"
      >
        <cube-button
          :light="true"
          class="btn-large"
          @click="toApply"
        >
          重新申请
        </cube-button>
      </div>
    </div>
  </div>
</template>

<script>
import viewMatch from '_c/view-match';
import { toArrays, isPcMobile } from '@/lib/until.js';
import { custModifyDetail } from '@/api/cust';
import { mapGetters } from 'vuex';
export default {
  components: {
    viewMatch
  },
  data() {
    return {
      custId: '',
      status: '',
      successImg: require('../../assets/img/icon_tips_success@2x.png'),
      failImg: require('../../assets/img/icon_tips_wrong@2x.png'),
      successText: '您申请的工商变更已审核通过',
      failText: '您申请的工商变更未通过审核',
      basicData: {
        modifyType: {
          title: '变更类型',
          value: ''
        },
        custCnOri: {
          title: '变更前名称',
          value: ''
        },
        custCnNew: {
          title: '变更后名称',
          value: ''
        },
        file: {
          type: 'textImg',
          title: '工商变更函',
          wxPreview: true,
          text: '',
          image: [],
          size: '',
          name: ''
          // image: '../../assets/img/logo.png'
        },
        licenseAfile: {
          type: 'textImg',
          title: '新营业执照',
          wxPreview: true,
          text: '',
          image: [],
          size: '',
          name: ''
          // image: '../../assets/img/logo.png'
        },
        opinion: {
          title: '审核意见',
          value: ''
        }
      }
    };
  },
  computed: {
    ...mapGetters(['dictList']),
    basicList() { // 对象是引用类型，传递的是引用地址，所以两个数组引用的是同一个对象，只要其中一个数组改变，就会导致对象改变，进而另一个引用的数组也会改。
      return toArrays(this.basicData);
    }
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      custModifyDetail(this.$route.query.id).then(res => {
        if (res.flag) {
          // 基本信息
          let data = res.data;
          this.custId = data.custId;
          this.status = data.status;
          this.basicData.modifyType.value = '客户名称变更';
          this.basicData.custCnOri.value = data.custCnOri;
          this.basicData.custCnNew.value = data.custCnNew;
          this.basicData.opinion.value = data.opinion;
          if (data.fileId) {
            this.basicData.file.text = '工商变更函附件';
            this.basicData.file.image.push(data.fileUrl);
            this.basicData.file.size = data.fileSize;
            this.basicData.file.name = data.fileUrl;
          };;
          if (data.licenseAfileId) {
            this.basicData.licenseAfile.text = '新营业执照';
            this.basicData.licenseAfile.image.push(data.licenseFileUrl);
            this.basicData.licenseAfile.size = data.licenseFileSize;
            this.basicData.licenseAfile.name = data.licenseFileUrl;
          }
        } else {
          this.$showToast(res.errorMsg);
        }
      });
    },
    toApply() {
      this.$router.push(
        {
          name: 'CustChange',
          query: {
            id: this.custId
          }
        }
      );
    },
    isPcMobileClass() {
      return isPcMobile() === 2 ? 'screenControl' : '';
    }
  }
};
</script>

<style scoped lang="scss">
@import '@/assets/css/components/btn-bottom.scss';
.page-out{
  background-color: $color-F7;
  .page-box{
    margin: 15px 15px 0 15px;
    overflow: hidden;
    background-color: $color-FFF;
    border-radius: 8px 8px 0 0;
    .img-box{
      width: 60px;
      height: 60px;
      margin: 32px auto;
      margin-bottom: 24px;
      img {
        width: 60px;
      }
    }
    .page-title{
      margin: 0 52px;
      color: #23252E;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
    }
    .page-content{
      margin: 32px 16px 0 16px;
      // border-bottom:1px solid #EAEBED;
    }
  }
}
</style>
